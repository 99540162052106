import { css } from '@emotion/react'
import styled from '@emotion/styled'
import ReactPlayer from 'react-player'
import { SystemIcon } from '@dfds-ui/icons'
import { media, theme } from '@dfds-ui/theme'
import { splitLocale, track } from '@dfds-frontend/tracking'
import { useLocaleContext } from '../LocaleContext'

const Video = (props) => {
  const { type, videoId, overlayImage, videoName, ...rest } = props
  const { locale } = useLocaleContext()
  const { countryCode, languageCode } = splitLocale(locale)
  const trackPayload = { event: 'video', videoName, languageCode, countryCode }
  const onPlay = track({ videoStatus: 'Play', ...trackPayload })
  const onPause = track({ videoStatus: 'Pause', ...trackPayload })
  const onEnded = track({ videoStatus: 'End', ...trackPayload })

  const PlayerWrapper = styled.div`
    position: relative;
    width: 100%;
    &::before {
      content: '';
      padding-bottom: 56.25%;
      height: 0;
      display: block;
    }
  `
  const reactPlayer = css`
    position: absolute;
    top: 0;
    left: 0;
    height: 50vh;
    width: 100%;

    ${media.greaterThan('xl')`
      height: auto;
    `}
  `

  const ReactPlayerYouTube = styled(ReactPlayer)`
    ${reactPlayer}
  `

  const ReactPlayerVimeo = styled(ReactPlayer)`
    ${reactPlayer}
  `

  return (
    <PlayerWrapper>
      {type === 'YouTube' && (
        <ReactPlayerYouTube
          url={`https://www.youtube.com/watch?v=${videoId}`}
          controls
          width="100%"
          height="100%"
          onPlay={onPlay}
          onPause={onPause}
          onEnded={onEnded}
          playIcon={
            <SystemIcon
              icon="Play"
              css={css`
                transform: translate(-50%, -50%);
                position: absolute;
                top: 50%;
                left: 50%;
                color: ${theme.colors.surface.primary}70;
                width: 120px;
                height: 120px;
              `}
            />
          }
          light={overlayImage}
          {...rest}
        />
      )}
      {type === 'Vimeo' && (
        <ReactPlayerVimeo
          url={`https://vimeo.com/${videoId}`}
          controls
          width="100%"
          height="100%"
          onPlay={onPlay}
          onPause={onPause}
          onEnded={onEnded}
          playIcon={
            <SystemIcon
              icon="Play"
              css={css`
                transform: translate(-50%, -50%);
                position: absolute;
                top: 50%;
                left: 50%;
                color: ${theme.colors.surface.primary}70;
                width: 120px;
                height: 120px;
              `}
            />
          }
          light={overlayImage}
          {...rest}
        />
      )}
    </PlayerWrapper>
  )
}

export default Video
